import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import {
	ActivatedRoute,
	NavigationCancel,
	NavigationEnd,
	NavigationExtras,
	NavigationStart,
	Router,
} from "@angular/router";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
	Observable,
	distinctUntilChanged,
	fromEvent,
	map,
	merge,
	startWith,
} from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { LanguagesService } from "@sportyano/core/services/language services/languages.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import { StaticWebsiteCredentialsService } from "@sportyano/core/services/language services/static-website-credentials.service";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { PrimeNGConfig } from "primeng/api";
@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit {
	progressBarState: boolean;
	constructor(
		private router: Router,
		private spinner: NgxSpinnerService,
		private loadingBar: LoadingBarService,
		private staticWebsiteCredentialsService: StaticWebsiteCredentialsService,
		private toastr: ToasterMessagesService,
		private translate: TranslateService,
		private languageService: LanguagesService, // IMPORTANT TO INITIALIZE LANGUAGE ACROSS APPLICATION - DON'T REMOVE THIS LINE
		@Inject(PLATFORM_ID) private platform: object,
		private authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private primeNGconfig: PrimeNGConfig
	) {}
	ngOnInit(): void {
		this.staticWebsiteCredentialsService.setCredentials(
			"developer@sportyano.com",
			'uAcxX85@778.'
		);

		this.autoAdminAccessLogin();
		this.router.events.subscribe({
			next: (event) => {
				if (
					isPlatformServer(this.platform) ||
					event instanceof NavigationStart
				) {
					this.spinner.show();
					this.loadingBar.start();
				} else if (
					isPlatformBrowser(this.platform) &&
					(event instanceof NavigationEnd ||
						event instanceof NavigationCancel)
				) {
					this.spinner.hide();
					this.loadingBar.complete();
				}
			},
		});

		if (isPlatformBrowser(this.platform)) {
			this.isOnline().subscribe((online) => {
				if (!online) {
					this.toastr.showInfo(
						this.translate.instant("No internet connection"),
						true
					);
				} else {
					this.toastr.showSuccess(
						this.translate.instant("Connection restored")
					);
					setTimeout(() => {
						this.toastr.clearToasters();
					}, 1500);
				}
			});
		}
		this.translate.get("primeng").subscribe((res) => {
			this.primeNGconfig.setTranslation(res);
		});
	}

	// To detect network connection
	private isOnline(): Observable<boolean> {
		return merge(
			fromEvent(window, "online").pipe(map(() => true)),
			fromEvent(window, "offline").pipe(map(() => false))
		).pipe(startWith(navigator.onLine), distinctUntilChanged());
	}

	private autoAdminAccessLogin() {
		this.activatedRoute.queryParams.subscribe({
			next: (res) => {
				if (
					res[this.authService.adminAccessStorageKey] &&
					isPlatformBrowser(this.platform)
				) {
					const parsedData = JSON.parse(
						res[this.authService.adminAccessStorageKey]
					);
					this.authService.autoAdminSignIn(parsedData);
				}
			},
		});
	}
}
