import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StaticWebsiteLoginComponent } from "@sportyano/auth/static-website-login/static-website-login.component";
import { staticWebsiteGuardGuard } from "@sportyano/core/guards/static-website-guard.guard";
import { PageNotFoundComponent } from "@sportyano/core/page-not-found/page-not-found.component";
import { UnauthorizedComponent } from "@sportyano/core/unauthorized/unauthorized.component";

const routes: Routes = [
	{
		path: "main",
		canActivate: [staticWebsiteGuardGuard],
		loadChildren: () =>
			import("@sportyano/main-site/main-site.module").then(
				(m) => m.MainSiteModule
			),
	},
	{
		path: "auth",
		canActivate: [staticWebsiteGuardGuard],
		loadChildren: () =>
			import("@sportyano/auth/auth.module").then((m) => m.AuthModule),
	},
	{
		path: "",
		redirectTo: "main",
		pathMatch: "full",
	
	},
	{
		path: "guard",
		component: StaticWebsiteLoginComponent,
	},
	{ path: "unauthorized", component: UnauthorizedComponent },
	{ path: "**", component: PageNotFoundComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: "top",
			enableViewTransitions: true,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
