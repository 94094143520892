import { isPlatformBrowser } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn } from '@angular/router';

export const staticWebsiteGuardGuard: CanActivateFn = () => {
  const platformId = inject(PLATFORM_ID);
  if(isPlatformBrowser(platformId)){
	const token = localStorage.getItem("staticGuardAccess");
	// COMMENT THIS CODE IN BASE BRANCH IN MASTER PULL REQUESTS
	if (!token) {
		window.location.href = "/guard";
		return false;
	}
	return true
  }
  return false;
};
