<!-- Routing/Http Progress bar Indicator -->
<ngx-loading-bar></ngx-loading-bar>

<!-- Main Routing -->
<router-outlet />

<!-- Background Loading -->
<ngx-spinner bdColor="rgb(0 0 0 / 90%)" [zIndex]="9999" size="medium" color="#fff" type="cube-transition">
	<p style="font-size: 20px; color: black"></p>
</ngx-spinner>

<!-- For LTR
<p-toast [preventOpenDuplicates]="true" position="top-left" key="bottomleft" class="custom-toast" /> -->

<!-- For RTL -->
<p-toast [preventOpenDuplicates]="true" position="top-right" class="custom-toast" />
<app-login-popup />
