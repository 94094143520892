import { isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { inject, PLATFORM_ID } from "@angular/core";
import { catchError, throwError } from "rxjs";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { NgxSpinnerService } from "ngx-spinner";
import { getCookie } from "@sportyano/core/common/utils/cookies-controller";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import { Router } from "@angular/router";

export const generalInterceptor: HttpInterceptorFn = (req, next) => {
	const platformId = inject(PLATFORM_ID);
	const authService = inject(AuthService);
	let token: string = getCookie("token") || "";
	let language: string = getCookie("lang") || "";
	const loadingBar = inject(LoadingBarService);
	const spinner = inject(NgxSpinnerService);
	const toastr = inject(ToasterMessagesService);
	const router = inject(Router);
	req = req.clone({
		setHeaders: {
			locale: language,
		},
	});
	if (token) {
		req = req.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
			},
		});
	}

	return next(req).pipe(
		catchError((error) => {
			if (error instanceof HttpErrorResponse && error.status === 401) {
				if (isPlatformBrowser(platformId)) {
					authService.removeAuthenticationData();
					authService.setAuthenticationState(false);
					router.navigate(["/"]);
					return throwError(() => new Error(""));
				}
			}

			if (error.error && isPlatformBrowser(platformId)) {
				loadingBar.stop();
				spinner.hide();
				if (error.error.errors) {
					const errorMessages = Object.values(
						error.error.errors
					).flat();
					errorMessages.forEach((errorMessage: any) => {
						toastr.showError(errorMessage);
					});
				} else if (error?.error?.message) {
					toastr.showError(error.error.message);
				} else if (error.error) {
					toastr.showError(error.error.error);
				} else {
					toastr.showError(
						"An unexpected error occurred. Please try again later."
					);
				}
			}
			return throwError("");
		})
	);
};
