import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import {
	HttpClient,
	provideHttpClient,
	withFetch,
	withInterceptors,
} from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from "ngx-spinner";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { generalInterceptor } from "@sportyano/core/interceptors/general.interceptor";
import { AppRoutingModule } from "@sportyano/app-routing.module";
import { AppComponent } from "@sportyano/app.component";
import { SharedModule } from "@sportyano/shared/shared.module";
import { MessageService } from "primeng/api";
import { environment } from "src/environments/environment";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { provideAuth, getAuth } from "@angular/fire/auth";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
	declarations: [AppComponent],
	imports: [
		RecaptchaV3Module,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		SharedModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		NgxSpinnerModule.forRoot({ type: "cube-transition" }),
		LoadingBarModule,
		LoadingBarHttpClientModule,
	],

	providers: [
		provideHttpClient(withFetch(), withInterceptors([generalInterceptor])),
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideAuth(() => getAuth()),
		MessageService,
		{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha_site_key }
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
